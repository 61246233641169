
<template>
<div class="mt-5">
    <div class="container-fluid pt-5">
            <main class="form-signin text-center">
                <form>
                    <h1 class="f_22 mb-3 fw-normal">MASTER PANEL</h1>

                    <div class="form-floating">
                        <input type="email" v-model="email" class="form-control" id="floatingInput" placeholder="Email Address">
                    </div>
                    <div class="form-floating">
                        <input type="password" v-model="password" class="form-control" id="floatingPassword" placeholder="Password">
                    </div>

                    
                    <button @click="login()" class="w-100 btn btn-lg btn-primary" type="button">Sign in</button>
                </form>
            </main>
    </div>
<div class="powered">Powered by Escapist</div>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,get,ref,child } from "firebase/database";
import store from '../store/index'
import MD5 from "crypto-js/md5";


const config = {
    apiKey: "AIzaSyAiMDdqTzBNfNh13XXzp-vo3k2RUf05p14",
    authDomain: "gloriousromangame.firebaseapp.com",
    databaseURL: "https://gloriousromangame-default-rtdb.firebaseio.com",
    projectId: "gloriousromangame",
    storageBucket: "gloriousromangame.appspot.com",
    messagingSenderId: "422195474770",
    appId: "1:422195474770:web:36927a0d5d8814ff2f8a8b"

};

const f_app = initializeApp(config);
//const dbRef = ref(db);



export default {

    components:{
        
    },

    data(){
        return {
            email: "",
            password: ""
        }
    },

    methods:{

        login(){

                const dbRef = ref(getDatabase(f_app));

                get(child(dbRef,"master")).then((snapshot) => {
                if (snapshot.exists()) {
                    let em = snapshot.val().email;
                    let pss = snapshot.val().password;

                    let md = MD5(this.password).toString();

                    if(em == this.email && pss == md){

                        store.commit('setMasterLogin', true);
                        this.$router.push('/master/home');
                    }else{
                        alert("Wrong password or email address. Please try again!");
                    }

                } else {
                    console.log("No data available");
                }
                }).catch((error) => {
                console.error(error);
                });
            
        },


        
    },

    mounted () {
        this.getGames();
    },

    
};


</script>

