
<template>
<div class="container  pb-5">
    <div class="text-center">


        


    </div>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,ref,set } from "firebase/database";

const config = {
    apiKey: "AIzaSyAiMDdqTzBNfNh13XXzp-vo3k2RUf05p14",
    authDomain: "gloriousromangame.firebaseapp.com",
    databaseURL: "https://gloriousromangame-default-rtdb.firebaseio.com",
    projectId: "gloriousromangame",
    storageBucket: "gloriousromangame.appspot.com",
    messagingSenderId: "422195474770",
    appId: "1:422195474770:web:36927a0d5d8814ff2f8a8b"

};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);



export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
        
        }
    },

    methods:{
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        addGame(){
            

            set(ref(db, this.randName()), {
                adminPassword: "dasdasd",
                gameId: this.randName(),

            });


        },
        
    },

    mounted () {
        
    },

    
};


</script>

