
<template>
<div class="">
<div class="container-fluid">
    <div class="row flex-nowrap">
        <LeftMenu />
        <div class="col py-3">
            
            <div class="f_22 font_semibold">ADD GAME</div>

            <div class="card mt-3">
                <div class="card-body">
                    
                    <div class="row mt-3">
                        <div class="col-3">Game Name - English</div>
                        <div class="col-9">
                            <input v-model="gameNameEnglish" class="form-control">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-3">Game Name - French</div>
                        <div class="col-9">
                            <input v-model="gameNameFrench" class="form-control">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-3">Game Duration - Minutes</div>
                        <div class="col-9">
                            <input v-model="gameDuration" class="form-control">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-3">Game Image</div>
                        <div class="col-9">
                            <input type="file"  @change="previewFiles" class="form-control-file" id="exampleFormControlFile1">
                        </div>
                    </div>



                    <div class="row mt-3">
                        <div class="col-12 text-right">
                            <button @click="createGame()" type="button" class="btn btn-primary">Add</button>
                        </div>
                        
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>








</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,ref,set } from "firebase/database";
import LeftMenu from '../components/LeftMenu.vue'
import axios from 'axios';
import store from '../store/index'

const config = {
    apiKey: "AIzaSyAiMDdqTzBNfNh13XXzp-vo3k2RUf05p14",
    authDomain: "gloriousromangame.firebaseapp.com",
    databaseURL: "https://gloriousromangame-default-rtdb.firebaseio.com",
    projectId: "gloriousromangame",
    storageBucket: "gloriousromangame.appspot.com",
    messagingSenderId: "422195474770",
    appId: "1:422195474770:web:36927a0d5d8814ff2f8a8b"

};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);



export default {

    components:{
        LeftMenu
    },

    data(){
        return {
            gameNameEnglish : "",
            gameNameFrench : "",
            gameDuration: 0,
            gameImage : null
        }
    },

    methods:{
        previewFiles(event) {
            this.gameImage = event.target.files[0];
        },
        previewFilesSoundTrack(event) {
            this.soundTrack = event.target.fileSoundTrack[0];
        },
        previewFilesClueSound(event) {
            this.clueSound = event.target.fileClueSound[0];
        },


        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        createGame(){

            if(this.gameImage == null || this.gameNameEnglish == "" || this.gameNameFrench == "" || this.gameDuration == 0){
                alert("Please fill all fields!");
            }else{

                const fd = new FormData();
                fd.append('image', this.gameImage);

                axios.post("https://gloriousromangame.com/api/addImage",fd
                ,{}
                ).then(
                    response => {
                        let rn = this.randName();
                        set(ref(db, 'games/'+rn), {
                            gameName_en: this.gameNameEnglish,
                            gameName_fr: this.gameNameFrench,
                            duration_default: this.gameDuration,
                            duration: this.gameDuration,
                            gameId: rn,
                            gameImage : response.data.imageName,
                            status : 'waiting', 
                            started : "0",
                            started_time: "0",
                            finished_time: "0",
                            show_time: "0",
                            extra_time: "0",
                            lang : "",
                            soundTrackPlay : "0",
                            lastClue: "0",
                            lastMessage: "0"

                        });

                        this.$router.push('/admin901/games');

                    }  
                );

            }


        },
        
    },

    mounted () {
        if(!store.getters.getAdminLogin){
            this.$router.push('/admin901');
        }
    },

    
};


</script>

