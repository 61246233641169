
<template>
<div class="">
<div class="container-fluid">
    <div class="row flex-nowrap">
        <LeftMenu />
        <div class="col py-3">
            <div class="f_22 font_semibold">CLUES - {{gameName}}</div>
            <div class="text-right"><button @click="show()" type="button" class="btn btn-warning">Add</button></div>
            

            <table class="table table-bordered mt-3">

                <th>Puzzle</th>
                <th>English</th>
                <th>French</th>
                <th></th>

                <tr v-for="clue in clues" :key="clue.clue_id">
                    <td>{{clue.clue_puzzle}}</td>
                    <td>{{clue.clue_en}}</td>
                    <td>{{clue.clue_fr}}</td>

                    <td width="200" class="text-right">
                        <button @click="deleteClue(clue.clue_id)" type="button" class="btn btn-danger mr-2">Delete</button>
                        <button @click="showEdit(clue.clue_id)" type="button" class="btn btn-warning">Edit</button>
                    </td>
                </tr>

            </table>

        </div>
    </div>
</div>



        <modal name="my-first-modal" >
            <div class="p-4">
            <div class="f_28 font_semibold mb-4" >Add Clue</div>
            <div class="mb-3"><input v-model="cluePuzzle" placeholder="Puzzle Name/Id" class="form-control"></div>
            <div class="mb-3"><input v-model="clueEng" placeholder="English" class="form-control"></div>
            <div class="mb-3"><input v-model="clueFr" placeholder="French" class="form-control"></div>
            <div class="text-center">
                <button @click="createClue()" type="button" class="btn btn-danger mr-2">Add</button>
            </div>
            </div>
        </modal>

        <modal name="my-edit-modal" >
            <div class="p-4">
            <div class="f_28 font_semibold mb-4" >Edit Clue</div>
            <div class="mb-3"><input v-model="cluePuzzle" placeholder="Puzzle Name/Id" class="form-control"></div>
            <div class="mb-3"><input v-model="clueEng" placeholder="English" class="form-control"></div>
            <div class="mb-3"><input v-model="clueFr" placeholder="French" class="form-control"></div>
            <div class="text-center">
                <button @click="saveClue()" type="button" class="btn btn-danger mr-2">Save</button>
            </div>
            </div>
        </modal>




</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set } from "firebase/database";
import LeftMenu from '../components/LeftMenu.vue'
import store from '../store/index'


const config = {
    apiKey: "AIzaSyAiMDdqTzBNfNh13XXzp-vo3k2RUf05p14",
    authDomain: "gloriousromangame.firebaseapp.com",
    databaseURL: "https://gloriousromangame-default-rtdb.firebaseio.com",
    projectId: "gloriousromangame",
    storageBucket: "gloriousromangame.appspot.com",
    messagingSenderId: "422195474770",
    appId: "1:422195474770:web:36927a0d5d8814ff2f8a8b"

};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);



export default {

    components:{
        LeftMenu
    },

    data(){
        return {
            gameId : 0,
            gameName : 0,
            clues : [],
            showModal: false,
            clueId: "",
            clueEng: "",
            clueFr: "",
            cluePuzzle: "",
        }
    },

    methods:{
        show () {
            this.$modal.show('my-first-modal');
        },
        hide () {
            this.$modal.hide('my-first-modal');
        },
        showEdit (id) {
            this.clues.forEach(e => {

                if(id == e.clue_id){
                    this.clueId = e.clue_id;
                    this.clueEng = e.clue_en;
                    this.clueFr = e.clue_fr;
                    this.cluePuzzle = e.clue_puzzle;
                }
                
            });
            this.$modal.show('my-edit-modal');

        },
        hideEdit () {
            this.$modal.hide('my-edit-modal');
        },

        saveClue(){

            let data = {
                clue_en: this.clueEng,
                clue_fr: this.clueFr,
                clue_puzzle: this.cluePuzzle,
                showed: 0
            }; 
            set(ref(db, 'games/'+this.gameId+"/clues/"+this.clueId), data );
            this.clueId = "";
            this.clueEng = "";
            this.clueFr = "";
            this.cluePuzzle = "";
            this.hideEdit();
            this.getClues();
        },

        createClue(){

            let rn = this.randName();
            set(ref(db, 'games/'+this.gameId+"/clues/"+rn), {
                clue_en: this.clueEng,
                clue_fr: this.clueFr,
                clue_puzzle: this.cluePuzzle,
                showed: 0
            });
            this.clueEng = "";
            this.clueFr = "";
            this.cluePuzzle = "";
            this.hide();
            this.getClues();
            
        },
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        deleteClue(id){
            if(confirm("Are you sure you want to delete?")){
                console.log(id);
                set(ref(db, 'games/'+this.gameId+"/clues/"+id), null );
                this.getClues();
            }
            
        },

        getGameDetails(){

                const dbRef = ref(getDatabase());
                get(child(dbRef,"games/"+this.gameId)).then((snapshot) => {
                if (snapshot.exists()) {
                    console.log(snapshot.val());
                    this.gameName = snapshot.val().gameName_en;
                } else {
                    console.log("No data available");
                }
                }).catch((error) => {
                console.error(error);
                });

        },

        getClues(){
            
            this.clues = [];
            let nRefusers = "/games/"+this.gameId+"/clues";
            get(child(dbRef, nRefusers)).then((snapshot) => {
            if (snapshot.exists()) {


                snapshot.forEach(e => {

                    let temp = {
                        clue_id:e.key,
                        clue_en: e.val().clue_en,
                        clue_fr:e.val().clue_fr,
                        clue_puzzle:e.val().clue_puzzle
                    };
                    this.clues.push(temp);
                }); 



            } 
            }).catch((error) => {
                console.log(error);
            }).then(()=> {
                //this.users = this.orderedItems(this.users);
            }).then(()=>{
                //this.users = this.users.splice(0, 10);
            });

            console.log(this.games);

        },
        
    },

    created() {
        this.gameId = this.$route.params.gameId;
        this.getGameDetails();
        this.getClues();
    },

    mounted () {
        if(!store.getters.getAdminLogin){
            this.$router.push('/admin901');
        }
    },

    
};


</script>

